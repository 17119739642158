import axios from 'axios'

class Api {
  constructor (config) {
    axios.create(config)
  }

  get = url => axios.get(url)

  post = (url, data) => axios.post(url, data)

  put = (url, data) => axios.put(url, data)

  delete = id => axios.delete(id)
}

export default Api
