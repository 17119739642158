import React from 'react'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import { Zoom } from '@material-ui/core'

const Loader = () => {
  const classes = useStyles()

  return (
    <Container className={classes.root}>
      <Zoom in style={{ transitionDelay: '300ms' }}>
        <img
          className={classes.loader}
          alt='Cargando...'
          src='./assets/resuelve-icon.svg'
        />
      </Zoom>
    </Container>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  },
  loader: {
    width: 90,
    height: 'auto',
    margin: '0 auto'
  }
}))

export default Loader
