import { observable, action, decorate } from 'mobx'
import SelfService from 'services/selfService'

const CUSTOM_AXIOS_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'MY_AUTH_TOKEN'
  }
}

const selfService = new SelfService(CUSTOM_AXIOS_CONFIG)

class SelfServiceStore {
  counter = 0
  githubData = {}

  addNumber = () => (this.counter += 1)

  loadGithubData = async () => {
    try {
      const { data } = await selfService.getGitHubData()
      this.githubData = { ...data }
    } catch (error) {
      console.error(error)
    }
  }
}

decorate(SelfServiceStore, {
  addNumber: action,
  counter: observable,
  loadGithubData: action,
  githubData: observable
})

export default new SelfServiceStore()
