import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Loader from 'components/molecules/loader'
const App = lazy(() => import('./App'))
const Simulator = lazy(() => import('./Simulator'))

const AppRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Switch>
          <Route exact path='/' component={App} />
          <Route exact path='/simulador' component={Simulator} />
        </Switch>
      </Router>
    </Suspense>
  )
}

export default AppRoutes
