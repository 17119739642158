import { createMuiTheme } from '@material-ui/core/styles'

// Override default theme
// https://material-ui.com/customization/default-theme/?expand-path=$.palette.primary
const theme = createMuiTheme({
  palette: {
    background: {
      default: '#fff'
    },
    primary: {
      light: '#4482D0', // blue_3
      main: '#002E70', // blue_1
      dark: '#004377', // blue_7
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#C4E0F7', // green_5
      main: '#7CAEE5', // green_3
      dark: '#004377' // green_1
    },
    error: {
      light: '#FFEEEE', // red_3
      main: '#EA7A7A', // red_2
      dark: '#D85B5B' // red_1
    },
    warning: {
      light: '#FFE183', // yellow-2
      main: '#FEA45A', // orange_2
      dark: '#EA872D' // orange_1
    },
    success: {
      light: '#D0E6A7', // green_4
      main: '#8DBA38', // orange_3
      dark: '#437124' // green_1
    },
    text: {
      primary: '#63656A',
      secondary: '#ffffff'
    },
    green: {
      light: '#D0E6A7',
      main: '#B2D66F'
    },
    blue: {
      light: '#F2F9FF'
    }
  },
  typography: {
    fontSize: 16,
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    body1: {
      color: '#63656A' // gray_2
    }
  },
  shadows: Array(25).fill('none')
})

theme.overrides = {
  MuiButton: {
    root: {
      borderRadius: 24,
      fontWeight: 700
    }
  },
  MuiTypography: {
    h1: {
      fontSize: '2rem'
    },
    h2: {
      fontSize: '1.5rem'
    },
    h3: {
      fontSize: '1.375rem'
    },
    h4: {
      fontSize: '1.125rem'
    }
  },
  MuiPaper: {
    root: {
      boxShadow: 'none'
    }
  },
  MuiContainer: {
    root: {
      [theme.breakpoints.up('lg')]: {
        paddingRight: '4rem',
        paddingLeft: '4rem'
      }
    }
  }
}

export default theme
